.projectContainer {
    height: fit-content;
}

.folderProjects {
    text-align: center;
}
.folderProjectsGrid {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 6vw);
    grid-template-rows: repeat(0, 6vw);
    grid-column-gap: 270px;
    grid-row-gap: 30px;
}