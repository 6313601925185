.aboutMeContainer {
    display: flex;
    justify-self: space-around;  
}
.aboutMeContent {
    width: 54%;
}

.aboutMeContent p {
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
}

.list {
    margin-top: 2px;
    line-height: 25px;
}

.list ul {
    list-style: none; 
}
  
.list ul li::before {
    content: "\25B9";  
    color: var(--text-green-color); 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em;
}

.list li {
    opacity: 0.85;
    font-size: 13px;
    font-family: var(--secondary-font);
}

.myPhotoReveal {
    margin-top: 9%;
    margin-right: 5%;
    margin-left: 10%;
    width: 350px;
    height: 350px;
}

.myPhotoReveal:hover .highlightBorder{
    animation-name: boxShadow;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    margin-left: -12px;
    margin-bottom: 12px;
}
.myPhotoReveal:hover .myPhoto {
    animation-name: removeGreyScale;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

}

@keyframes removeGreyScale {
    to {
        filter: grayscale(0%);
    }
}

@keyframes boxShadow {
    to {
        background-color: red;
        box-shadow: 4px 4px 2px 2px var(--text-green-color);
    }
}

.myPhoto {
    width: 320px;
    z-index: 2;
    position: relative;
    right: 20px;
    bottom: 345px;
    border-radius: 5px;
    filter: grayscale(100%);
}

.highlightBorder {
    z-index: 1;
    width: 320px;
    height: 320px;
    border: 2px solid var(--text-green-color);
    border-radius: 5px;
}