.navBar {
    display: flex;
    justify-content: space-between;
    padding: 20px 60px 10px 50px;
    position: sticky;
    top: 0px;
    background-color: var(--main-bg-color);
    z-index: 99999;
    opacity: 0.95;
}
a {
    text-decoration: none;
}

.logo {
    width: 40px;
}

.navBarItems li{
    font-size: 14px;
    display: inline;
    margin-left: 30px;    
    color: var(--text-grey-color);
    font-family: 'Fira Code', monospace;
}
.navBarItems span {
    margin-right: 4px;
}
.navBarItems li:hover {
    animation-name: changeNavItemColor;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes changeNavItemColor {
    to {
        color: var(--text-green-color);
    }    
}

.navBarItems span {
    color: var(--text-green-color);
}

.resumeBtn {
    margin-left: 50px;
    font-size: 15px;
}